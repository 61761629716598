<template>
  <v-container id="loadreport" fluid tag="section" height="">
    <confirm-dlg ref="confirm"></confirm-dlg>
    <v-row>
      <v-col cols="12" md="12">
        <v-timeline align-top dense>
          <v-timeline-item class="white--text mb-6" icon="mdi-fingerprint" fill-dot :color="highItemColor('verifyAccessKey')">
            <v-row justify="space-between">
              <v-col>
                <h3 :class="highProgress('verifyAccessKey')">Verifying access key....</h3>
                <h3 v-show="error" class="display-2 red--text mt-2">
                  <v-icon class="red--text mb-1" style="font-size: 36px"> mdi-alert-octagon-outline </v-icon>
                  We are unable to verify the access key. Please contact your service provider.
                </h3>
              </v-col>
            </v-row>
          </v-timeline-item>
          <v-timeline-item class="white--text mb-6" :color="highItemColor('comparePreviousReport')" icon="mdi-magnify" fill-dot>
            <v-row justify="space-between">
              <v-col>
                <h3 :class="highProgress('comparePreviousReport')">Check if this report was previously downloaded...</h3>
              </v-col>
            </v-row>
          </v-timeline-item>
          <v-timeline-item class="white--text mb-6" :color="highItemColor('downloadReport')" :icon="spinIcon('downloadingReport')" fill-dot>
            <v-row justify="space-between">
              <v-col>
                <h3 :class="highProgress('downloadReport')">Downloading report from Billview Portal...</h3>
                <v-progress-linear
                  v-model="progress"
                  color="green accent-4"
                  rounded
                  striped
                  height="20"
                  style="max-width: 350px"
                  v-show="this.progress > 0 && verifyAccessKey"
                >
                  {{ progress }}%
                </v-progress-linear>
              </v-col>
            </v-row>
          </v-timeline-item>
          <v-timeline-item class="white--text mb-6" :color="highItemColor('computeData')" :icon="spinIcon('computingData')" fill-dot>
            <v-row justify="space-between">
              <v-col>
                <h3 :class="highProgress('computeData')">Computing data...</h3>
              </v-col>
            </v-row>
          </v-timeline-item>
          <v-timeline-item class="white--text mb-6" :color="highItemColor('redirectToDashboard')" icon="mdi-flag-checkered" fill-dot>
            <v-row justify="space-between">
              <v-col>
                <h3 :class="highProgress('redirectToDashboard')">Redirecting to dashboard...</h3>
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FetchReportHelper from '@/api/FetchReportHelper'
import { mapGetters } from 'vuex'
import { loadData, loadBuybackData, resetGlobalAccountFilter } from '@/api/Preprocessor'
// const { mapGetters } = createNamespacedHelpers('fileDownloadProgress')

import store from '@/store'

export default {
  name: 'LoadReport',
  components: {
    ConfirmDlg: () => import('@/views/blitz/components/ConfirmReplaceBlitzFile')
  },
  props: {
    snData: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      verifyAccessKey: false,
      comparePreviousReport: false,
      downloadingBlitzFile: false,
      downloadReport: false,
      computeData: false,
      computingFile: false,
      redirectToDashboard: false,
      loaded: false,
      snDataLocal: null,
      timeOut: 0,
      error: false,
      partner: null
    }
  },
  computed: {
    ...mapGetters('app', {
      customerInfo: 'customerInfo',
      buybackAvailable: 'buybackAvailable'
    }),
    ...mapGetters('fileDownloadProgress', {
      byteDownloaded: 'byteDownloaded',
      totalByte: 'totalByte'
    }),
    progress() {
      return this.byteDownloaded ? Math.round((this.byteDownloaded / this.totalByte) * 100) : 0
    }
  },
  // use this to test http://localhost:8081/#/loadreport/c878759d-0bc6-41a6-8a72-83e7807d19c7
  // c878759d-0bc6-41a6-8a72-83e7807d19c7
  // the mounted method is called everytime the user enter the link into the browser address bar
  mounted() {
    this.snDataLocal = this.$route.params.sn
    const accessKey = this.snDataLocal
    // reset all progress flag..
    this.verifyAccessKey = false
    this.comparePreviousReport = false
    this.downloadingReport = false
    this.downloadReport = false
    this.computingData = false
    this.computeData = false
    this.redirectToDashboard = false
    console.log('LoadReport.vue mounted via route with sn ' + this.$route.params.sn)
    const storedCustomerInfo = this.customerInfo
    if (storedCustomerInfo) {
      // customer info exists
      console.log('customerInfo is ', storedCustomerInfo)
      const savedFileName = storedCustomerInfo.fileName
      this.checkIfFileAlreadyDownloaded(savedFileName, accessKey)
    } else {
      //process to download the file as this is the first time customer usess this app...
      this.downloadImmediate(accessKey)
    }
  },
  methods: {
    highProgress(textName) {
      const colorClass = 'display-2 grey--text'
      const colorDoneClass = 'display-2 black--text text--darken-2'
      if (textName === 'verifyAccessKey') {
        return this.verifyAccessKey ? colorDoneClass : colorClass
      }
      if (textName === 'comparePreviousReport') {
        return this.comparePreviousReport ? colorDoneClass : colorClass
      }
      if (textName === 'downloadReport') {
        return this.downloadReport ? colorDoneClass : colorClass
      }
      if (textName === 'computeData') {
        return this.computeData ? colorDoneClass : colorClass
      }
      if (textName === 'redirectToDashboard') {
        return this.redirectToDashboard ? colorDoneClass : colorClass
      }
    },
    spinIcon(textName) {
      if (textName === 'downloadingReport') {
        return this.downloadingReport ? 'mdi-spin mdi-loading' : 'mdi-download'
      }
      if (textName === 'computingData') {
        return this.computingData ? 'mdi-spin mdi-loading' : 'mdi-puzzle'
      }
    },
    highItemColor(textName) {
      const colorClass = '#fad895'
      const colorDoneClass = 'green'
      if (textName === 'verifyAccessKey') {
        return this.verifyAccessKey ? colorDoneClass : colorClass
      }
      if (textName === 'comparePreviousReport') {
        return this.comparePreviousReport ? colorDoneClass : colorClass
      }
      if (textName === 'downloadReport') {
        if (this.downloadingReport) {
          return colorDoneClass
        }
        return this.downloadReport ? colorDoneClass : colorClass
      }
      if (textName === 'computeData') {
        if (this.computingData) {
          return colorDoneClass
        }
        return this.computeData ? colorDoneClass : colorClass
      }
      if (textName === 'redirectToDashboard') {
        return this.redirectToDashboard ? colorDoneClass : colorClass
      }
    },
    async checkIfFileAlreadyDownloaded(currentFileName, accessKey) {
      // now check if the storeFileName matches the file provided by the link...
      try {
        const loginTokenInfo = await FetchReportHelper.getLoginTokenInfo(accessKey)
        this.verifyAccessKey = true
        const newfileName = loginTokenInfo.fileName
        this.partner = {
          partnerBlitzLogo: loginTokenInfo.partnerBlitzLogo,
          partnerBlitzIcon: loginTokenInfo.partnerBlitzIcon,
          navbarTitle: loginTokenInfo.navbarTitle,
          sidebarColor: loginTokenInfo.sidebarColor,
          activeButtonColor: loginTokenInfo.activeButtonColor,
          sidebarTextColor: loginTokenInfo.sidebarTextColor,
          accessKey: accessKey
        }
        console.log('checkIfFileAlreadyDownloaded current file ' + currentFileName + ' new file ' + newfileName)
        if (newfileName === currentFileName) {
          // check with user with they want to proceed
          this.comparePreviousReport = true
          if (
            await this.$refs.confirm.open(
              'Download blitz file',
              'The Blitz file was downloaded previously. Would you like to continue and replace the file?',
              { color: 'orange' }
            )
          ) {
            // user decide to replace local file
            return this.downloadBlitzFile(loginTokenInfo, accessKey)
          } else {
            // user decide to keep
            return this.$router.push('/')
          }
        } else {
          this.comparePreviousReport = true
          return this.downloadBlitzFile(loginTokenInfo, accessKey)
        }
      } catch (err) {
        this.error = true
      }
    },
    async downloadImmediate(accessKey) {
      return FetchReportHelper.getLoginTokenInfo(accessKey).then((fileDetail) => {
        return fileDetail
      })
    },
    async downloadBlitzFile(tokenInfo, accessKey) {
      console.log('downloadBlitzFile - I am here ' + tokenInfo.fileName)
      return FetchReportHelper.loginToPortalWithToken(tokenInfo)
        .then(() => {
          this.downloadingReport = true
          console.log('setting downloadingReport to ' + this.downloadingReport)
          return FetchReportHelper.downloadBuybackFileWithProgress(accessKey)
        })
        .then((result) => {
          //Set 300 timeout to compelete the download file progress
          return this.Sleep(300).then(() => {
            return result
          })
        })
        .then((buybackPriceExcel) => {
          if (buybackPriceExcel != null) {
            console.log('buyback price file is available')
            return FetchReportHelper.convertExceltoArray(buybackPriceExcel.fileName, buybackPriceExcel.buybackFile)
          }
        })
        .then((buybackPriceArray) => {
          if (buybackPriceArray != null) {
            store.dispatch('app/setBuybackAvailable', true)
            return loadBuybackData(buybackPriceArray.fileName, buybackPriceArray.rows)
          } else {
            store.dispatch('app/setBuybackAvailable', false)
          }
        })
        .then(() => {
          return FetchReportHelper.downloadBlitzFileWithProgress(tokenInfo.fileName, accessKey)
        })
        .then((result) => {
          //Set 300 timeout to compelete the download file progress
          return this.Sleep(300).then(() => {
            return result
          })
        })
        .then((result) => {
          return FetchReportHelper.convertExceltoArray(result.fileName, result.file)
        })
        .then((result) => {
          this.computingData = true
          this.downloadReport = true
          this.downloadingReport = false
          return loadData(result.fileName, result.rows, this.partner)
        })
        .then((result) => {
          store.dispatch('app/setFirstTimeUse', false)
          this.computeData = true
          return resetGlobalAccountFilter()
        })
        .then((result) => {
          this.computingData = false
          this.redirectToDashboard = true
          this.$router.push('/')
        })
    },
    Sleep(milliseconds) {
      return new Promise((resolve) => (this.timeOut = setTimeout(resolve, milliseconds)))
    }
  }
}
</script>

<style scoped></style>
